import { Box, FormControlLabel, Grid, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import DrawerLayout from '../Layouts/DrawerLayout';
import { useFileContext } from '../../contexts/FilesContext';
import AssetDetails from '../Lists/AssetDetails';
import { useEffect, useState } from 'react';
import DrawerStepper from '../Layouts/DrawerStepper';
import { ASSET_TYPES, IMAGE_PREFIX } from '../../utils/enums';
import ColorPickerCustom from '../Inputs/ColorPickerCustom';
import DrawerFileUploader from '../Inputs/DrawerFileUploader';
import useCreateColor from '../../api/hooks/files/useCreateColor';
import useUpdateAsset from '../../api/hooks/files/useUpdateAsset';
import useCreateAsset from '../../api/hooks/files/useCreateAsset';
import useUploadFileToDbx from '../../api/hooks/files/useUploadFileToDbx';
import useUploadAssetFile from '../../api/hooks/files/useUploadAssetFile';
import { ALERT_STATUS, useAlert } from '../../contexts/AlertContext';
import useCreateRelatedFile from '../../api/hooks/files/useCreateRelatedFile';
import {
	getFileExtension,
	getImageSource,
	handleImgToDisplay,
} from '../../utils/globals';
import { useLocation } from 'react-router-dom';
import { areFilesEqual } from '../../utils/calculations';
import useCreateThumbnail from '../../api/hooks/files/useCreateThumbnail';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { useIsMutating } from '@tanstack/react-query';
import GeneralButton from '../Inputs/GeneralButton';
import palette from '../../styles/palette';

const STEPS = {
	ASSET: 'ASSET',
	DETAILS: 'DETAILS',
};

const AssetDrawer = ({ isOpen, setIsOpen }) => {
	const { handleAlert } = useAlert();
	const {
		setCurrentFile,
		currentFile,
		assetType,
		setFiles,
		isCreating,
		isEditing,
		refetchFiles,
		currentFolder,
	} = useFileContext();
	const location = useLocation();
	const [file, setFile] = useState(null);
	const [isImage, setIsImage] = useState(true);
	const [step, setStep] = useState(STEPS.ASSET);
	const [imageSource, setImageSource] = useState(null);

	const isMutating = useIsMutating();

	const successUpload = (data) => {
		(isMutating === 0) & handleClose();
	};

	const { handleUploadFileToDbx } = useUploadFileToDbx((data) => {
		setCurrentFile(data?.data);
		setFiles((prev) =>
			prev.map((file) =>
				file.id === data?.data?.id ? data?.data : file,
			),
		);
	});

	const { handleCreateColor } = useCreateColor((data) => {
		setFiles((prev) => [...prev, data.data]);
		handleClose();
	});

	const { handleUpdateAsset } = useUpdateAsset((data) => {
		if (assetType == ASSET_TYPES.COLOR) return handleClose();

		const filesToUpload = currentFile?.relatedFiles?.filter(
			(file) => !file?.id,
		);

		if (filesToUpload?.length) {
			for (const file of filesToUpload) {
				const formData = new FormData();
				formData.append('assetId', data.data.id);
				formData.append('asset', file);
				handleCreateRelatedFile(formData);
			}
		}

		if (currentFile?.thumbnail?.file) {
			const thumbnailData = new FormData();
			thumbnailData.append('assetId', data.data.id);
			thumbnailData.append('asset', currentFile?.thumbnail?.file);
			thumbnailData.append(
				'crop',
				JSON.stringify(currentFile?.thumbnail?.crop),
			);
			return handleCreateThumbnail(thumbnailData);
		}
		(isMutating === 0) & handleClose();
	});

	const { handleCreateThumbnail } = useCreateThumbnail(successUpload);

	const uploadRelatedFiles = (data) => {
		for (const file of currentFile.relatedFiles) {
			const formData = new FormData();
			formData.append('assetId', data.data.id);
			formData.append('asset', file);
			handleCreateRelatedFile(formData);
		}
	};

	const { handleCreateRelatedFile } = useCreateRelatedFile(successUpload);

	const { handleUploadAssetFile } = useUploadAssetFile((data) => {
		if (currentFile?.relatedFiles?.length > 0) {
			return uploadRelatedFiles(data);
		}
		if (currentFile?.thumbnail) {
			const isEqual = areFilesEqual(
				currentFile?.thumbnail?.file,
				currentFile?.asset,
			);
			if (isEqual) {
				return handleUpdateAsset({
					...currentFile,
					id: data?.data?.id,
				});
			} else {
				const thumbnailData = new FormData();
				thumbnailData.append('assetId', data.data.id);
				thumbnailData.append('asset', currentFile?.thumbnail?.file);
				thumbnailData.append(
					'crop',
					JSON.stringify(currentFile?.thumbnail?.crop),
				);

				return handleCreateThumbnail(thumbnailData);
			}
		}
		successUpload(data);
	});

	const { handleCreateAsset } = useCreateAsset((data) => {
		const formData = new FormData();
		formData.append('assetId', data.data.id);
		formData.append('asset', currentFile?.asset);
		handleUploadAssetFile(formData);
	});

	const handleSave = () => {
		if (!currentFile?.id && !file && assetType !== ASSET_TYPES.COLOR)
			return handleAlert(ALERT_STATUS.ERROR, 'Please upload asset.');
		if (!currentFile?.name)
			return handleAlert(ALERT_STATUS.ERROR, 'Asset must have a name.');

		const typeAsset = currentFile?.id
			? currentFile?.mimetype
			: currentFile?.asset?.type;

		if (!typeAsset?.includes('image') && assetType !== ASSET_TYPES.COLOR) {
			if (
				!currentFile?.thumbnail &&
				!currentFile?.thumbnail?.file &&
				!currentFile?.Thumbnail
			)
				return handleAlert(
					ALERT_STATUS.ERROR,
					'Asset that is not an image must have a thumbnail.',
				);
		}

		if (isCreating) {
			assetType === ASSET_TYPES.COLOR
				? handleCreateColor(currentFile)
				: handleCreateAsset(currentFile);
		} else if (isEditing) {
			handleUpdateAsset(currentFile);
		}
	};

	const handleClose = () => {
		refetchFiles(currentFolder?.id);
		setIsOpen(false);
		setIsImage(true);
		setCurrentFile(null);
		location.state = {};
	};

	const handleSetImageSource = async () => {
		setImageSource(
			await handleImgToDisplay(currentFile, IMAGE_PREFIX.CARD),
		);
	};

	useEffect(() => {
		if (
			!Object.hasOwn(currentFile, 'name') ||
			currentFile?.name === undefined
		) {
			setCurrentFile((prev) => ({
				...prev,
				name: prev?.asset?.name,
			}));
		}
	}, [file]);

	useEffect(() => {
		if (currentFile) {
			handleSetImageSource();
		}
	}, [currentFile]);

	return (
		<DrawerLayout
			title={
				<>
					{isCreating ? 'Add Asset ' : 'Edit Asset '}
					{currentFile?.type !== ASSET_TYPES.COLOR &&
						!currentFile?.dbxPath &&
						!isCreating && (
							<GeneralButton
								variant={'text'}
								sx={{
									marginLeft: 2,
									color: palette.warning.main,
								}}
								onClick={() => {
									handleUploadFileToDbx({
										assetId: currentFile?.id,
									});
								}}
							>
								Upload to Dropbox
							</GeneralButton>
						)}
				</>
			}
			isOpen={isOpen}
			handleCloseDrawer={handleClose}
			handleSave={() =>
				step === STEPS.ASSET ? setStep(STEPS.DETAILS) : handleSave()
			}
			saveText={step === STEPS.ASSET ? 'Next' : 'Save'}
		>
			{step === STEPS.ASSET && (
				<>
					<Grid
						container
						gap={2}
						alignItems={'center'}
						sx={{ border: 'unset !important' }}
					>
						<FormControlLabel
							control={
								<Switch
									checked={currentFile?.hiddenFile}
									onChange={(e) => {
										setCurrentFile((prev) => ({
											...prev,
											hiddenFile: e.target.checked,
										}));
									}}
								/>
							}
							label='Not for display'
						/>
						<FormControlLabel
							control={
								<Switch
									checked={currentFile?.disableDownload}
									onChange={(e) => {
										setCurrentFile((prev) => ({
											...prev,
											disableDownload: e.target.checked,
										}));
									}}
								/>
							}
							label='Disable download'
						/>
					</Grid>
					{assetType === ASSET_TYPES.COLOR ? (
						<ColorPickerCustom />
					) : (
						<Grid
							container
							gap={2}
							direction='column'
							sx={{ border: 'unset !important' }}
						>
							{isCreating ? (
								<>
									<DrawerFileUploader
										buttonText='Asset'
										file={file}
										fieldName={'asset'}
										inputId={'asset-input'}
										inputName={'asset'}
										currentItem={currentFile?.localPath}
										setCurrentItem={setCurrentFile}
										setFile={setFile}
										assetType={assetType}
										setIsImage={setIsImage}
										isImage={isImage}
									/>
								</>
							) : currentFile?.mimetype?.includes('image') ? (
								<Box
									width={'100%'}
									sx={{
										borderRadius: '24px',
										overflow: 'hidden',
									}}
								>
									<img
										{...imageSource}
										alt={currentFile?.name}
										loading='lazy'
										style={{
											height: 170,
											width: '100%',
											objectFit: 'cover',
										}}
									/>
								</Box>
							) : (
								<Box sx={{ maxWidth: '25%' }} mx={'auto'}>
									<FileIcon
										extension={getFileExtension(
											currentFile?.dbxPath,
										)}
										{...defaultStyles[
											getFileExtension(
												currentFile?.dbxPath,
											)
										]}
									/>
								</Box>
							)}

							{currentFile?.size && (
								<AssetDetails asset={currentFile} />
							)}
						</Grid>
					)}
				</>
			)}
			{step === STEPS.DETAILS && (
				<Grid
					container
					direction={'column'}
					gap={1}
					sx={{ border: 'unset !important' }}
				>
					<DrawerStepper excludeStep={1} />
				</Grid>
			)}
		</DrawerLayout>
	);
};

AssetDrawer.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
};
export default AssetDrawer;
